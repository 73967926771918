.outer-box {
  @apply w-28 flex-row justify-center align-middle;

  backface-visibility: hidden;
  user-select: none;
  
  transform: translate(-50%, -160%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.7s;
  
  white-space: nowrap;
}

.name-box {
  @apply h-8 text-lg text-center flex justify-center items-center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.planet-box {
  @apply h-8;
  transition: all 0.25s;

  display: flex;
  justify-content: space-evenly;
}

.status-box{
  width: 100%;
  height: 100%;

  backdrop-filter: blur(8px) contrast(0.8);
  padding: 0.5em;
  border: 1px solid #bba;
  transform: translate(-3px, -3px);
}

.navbar {
  border-bottom: 1px solid #888;
}

.navbar-btn {
  border: 1px solid #bbb;
  margin: 4px;
  border-radius: 2px;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono&family=Saira+Condensed&display=swap");

body,
html {
  @apply bg-slate-700;
  color: white;
  font-family: "Saira Condensed", Helvetica, sans-serif;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

Canvas {
  @apply w-screen h-screen;
  background-image: linear-gradient(155deg, #1b1b44, #04000b);
  pointer-events: auto;
}

.app-wrapper {
  pointer-events: none;
  user-select: none;
}

.overlay-upper-left {
  @apply w-96 h-64;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;

  margin: 0.5em;
  border: 1px solid #666;
  transform: translate(3px, 3px);
}

/* .overlay-upper-right {
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  border: 1px solid #666;
  display: inline-block;
} */

.overlay-lower-left {
  font-size: 16px;
  z-index: 20;
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid #666;
}
